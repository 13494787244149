import React from 'react'
import '../css/abordo_al_bolante.css'
export default function Tyc() {
  return (
    <>
       <section>
            <div className='header_section'>
                <div className='container header_section_box'>
                    <div className='header_section_title'>
                        <h3>Términos y Condiciones  </h3>
                        <p>Promoción “Abordo y al volante”</p>
                    </div>                
                </div>
            </div>
        </section>
    <section>
      <div className='container'>
        <div className='abordo_al_bolante'>
          <h3>Términos y Condiciones de la Promoción “Abordo y al volante”.</h3>
          <p>Los presentes Términos y Condiciones descritos a continuación (las “<strong>Bases</strong>”) establecen las condiciones y
          requisitos bajo los cuales se regirá la actividad promocional “<strong>Abordo y al Volante</strong>” (en lo sucesivo “La Promoción”).
          Lo aquí dispuesto es obligatorio para todos los participantes de la Promoción, por lo que deberá dar lectura a todas
          las presentes Bases. La participación en la Promoción se interpretará como una aceptación expresa de las Bases,
          de las condiciones y términos de uso, así como del Aviso de Privacidad para el tratamiento de datos personales.</p>

          <p><strong>1. DATOS GENERALES.</strong></p>
          <p><strong>Nombre de la promoción:</strong> “<i>Abordo y al Volante</i>”.</p>
          <p><strong>Cobertura:</strong> Promoción válida en todas las rutas entre Sinaloa y Baja California Sur.</p>

          <p><strong>Vigencia:</strong>La promoción tendrá vigencia y será válida a nivel nacional desde las 12:01 am (GMT-7) el 01 de
          noviembre de 2024 a las 12:01 am (GMT-7) hora en Mazatlán, Sinaloa y concluye el 31 de diciembre de 2024 a las
          11:59 pm hora en Mazatlán, Sinaloa.</p>

          <p><strong>Naturaleza:</strong> Para participar en la Promoción <strong>“Abordo y al volante”</strong> (en lo sucesivo la <strong>“Promoción”</strong>), El Consumidor
          acepta y reconoce haber leído íntegramente los siguientes <strong>Términos y Condiciones</strong> (en adelante las <strong>“Bases”</strong>) y
          cumplir totalmente con los requisitos y condiciones aquí establecidos, lo cual implicará la comprensión y aceptación
          de estas. <strong>La Promoción</strong> se ejecutará dentro del Whatsapp a través del número <strong>5641590866</strong> (en lo sucesivo
          "<strong>Whatsapp</strong>") y el ingreso y registro a la misma implicará la aceptación a los <strong>Términos de uso</strong> de este <strong>Whatsapp </strong>
          por parte del participante y como <strong>Participante</strong> al registrarse a esta <strong>Promoción</strong>. En caso de cambios o
          modificaciones en las presentes Bases, “El Organizador” realizará las actualizaciones necesarias en las mismas,
          publicando la fecha de su última actualización, por tanto el <strong>Participante</strong> interesado deberá revisar periódicamente
          las <strong>Bases</strong> para conocer las modificaciones realizadas.</p>

          <p>Por lo anterior, y al margen de lo que dispone la Ley Federal de Protección al Consumidor y la NOM- 028-SCFI-2007
          se hace pública la presente promoción denominada “<strong>Abordo y al volante</strong>”, por tratarse de una promoción por
          medio de una dinámica en la cual el participante requiere de su exclusiva habilidad y conocimiento para obtener un
          incentivo y no un juego de azar o sorteo, por lo cual la misma no se sujeta a la obtención de un permiso expedido
          por la Secretaría de Gobernación.</p>
          <p><strong>Nombre de la promoción: “Abordo y al volante”</strong>, (en lo sucesivo referida como “<strong>la Promoción</strong>”).</p>
          <p><strong>Nombre y domicilio del responsable de la promoción:</strong> PEOPLE INCORPORATED, S.A. de C.V. (en adelante “La
          Organizadora"), con domicilio en Damas 129, Col. San José Insurgentes, Alcaldía Benito Juárez, C.P. 03900, Ciudad
          de México.</p>

          <p>Baja Ferries S.A.P.I. de C.V. y sus subsidiarias no son responsables ni organizadores de la presente Promoción, por
          lo que cualquier aclaración, reclamación o disputa sobre la aplicación de la promoción, sus términos y condiciones o
          entrega de incentivos, se deberán dirigir a la “Organizadora”, incluyendo, pero no limitando, la selección y anuncio
          de los ganadores o la distribución de los incentivos.</p>
          <p><strong>Vigencia de la promoción:</strong> El periodo de participación de la mecánica de “la Promoción” inicia a las 12:01 am
          hrs(GMT-7) Hora en Mazatlán Sinaloa del día 1 de noviembre de 2024 y concluye a las 11:59 pm(hora (GMT-7) Hora
          en Mazatlán Sinaloa del 31 de diciembre de 2024.</p>

          <p><strong>Cobertura Geográfica:</strong> Mazatlán, Sinaloa, Topolobampo, Sinaloa y La Paz, Baja California Sur.</p>

           
            <p><strong>Teléfono para información y aclaraciones:</strong> El teléfono 8003377437, de lunes a viernes de 10:00 a 17:00 hrs., a
            excepción de los días sábado, domingo y días festivos oficiales que se considerarán inhábiles para atención.</p>
            <p><strong>2. DESCRIPCIÓN GENERAL:</strong> Podrá participar el público consumidor mayor de edad que transporta carga por
            medio de los buques, que sean originarios o residentes de la República Mexicana, mayores de edad, con
            identificación oficial vigente, operadores de transporte de carga, con domicilio en la República Mexicana y que
            efectúe la compra de al menos 1 (un) pase de abordar junto a 1(un) pase de unidad de carga para viajar en Baja
            Ferries®, así como cumplir con la mecánica de participación del punto 2 y 7 de las presentes Bases, para entonces
            ser considerado como participante (en lo sucesivo “Participante” o “Participantes”).</p>
            <p>A efectos de la presente promoción, se considerará que los términos "operadores de carga" y "transportistas" son
            sinónimos y hacen referencia a personas físicas que transportan carga de manera personal y directa, utilizando un
            vehículo de carga. Se aclara que, aunque los operadores de carga puedan estar vinculados a empresas de
            transporte, la participación en esta promoción es estrictamente individual y no se extiende a las personas morales.
            Cualquier participación que se intente hacer fuera de los requisitos indicados, será considerada como inválida y será
            cancelada.</p>

            <p><strong>3. PRODUCTOS PARTICIPANTES:</strong>
            Participan las compras efectuadas para realizar un viaje en Baja Ferries® que incluya un acceso al ferri como pase
            de abordar junto al pase de unidad de carga.</p>

            <p>● <strong>Vehículos participantes</strong> <br />
            ○ Tractor, Doble Rodado, Torthon, Grúa, Mudanza, Caja de trailer, Trailer o pipa sencilla, Trailer o
            pipa semi full, Trailer o pipa full.</p>

            <p><strong>4. TOTAL DE INCENTIVOS:</strong> Se ofrecerán, un total de 9 incentivos durante toda la promoción, de los cuales 8
            (ocho) corresponden a incentivos que se otorgarán por medio de una prelación establecida, por registro de pase de
            abordar y de unidad de carga válidos y 1 (un) incentivo final, que estará sujeto a la dinámica de participación
            correspondiente, consistentes en el conteo de camioncitos dentro de la urna:</p>

            {/* TABLA */}
            <table class="table table-bordered mascota-tabla">
                <tbody>            
                    <tr>                                    
                        <th className="">INCENTIVOS POR PERIODO O TIPO DE PARTICIPACIÓN	</th>
                        <th className="">CANTIDAD TOTAL DISPONIBLE</th>   
                        <th className="">DESCRIPCIÓN DE INCENTIVO</th>                                  
                    </tr>
                    <tr>
                        <td>Lava secadora</td>
                        <td>2</td>
                        <td>LAVASECADORA Samsung WD20T6000GW/AX</td>                                                                   
                    </tr>
                    <tr>
                        <td>Refrigerador</td>
                        <td>2</td>
                        <td>REFRIGERADOR Samsung RF32CG5N10S9EM</td>                                                                   
                    </tr>
                    <tr>
                        <td>Smart tv</td>
                        <td>2</td>
                        <td>SMART TV Samsung UN65DU7000FXZX</td>                                                              		        					      
                    </tr>	
                    <tr>
                        <td>$25,000 MXN</td>
                        <td>2</td>
                        <td>Monedero sivale con valor de $25,000</td>                                                              
                    </tr>	
                    <tr>
                        <td>Kia K3 L</td>
                        <td>1</td>
                        <td>Kia K3 Sedán 2025</td>                                                               
                    </tr>                                                                			                                            
                </tbody>
            </table>

          <p><strong>*La entrega de los incentivos estará condicionada al cumplimiento de las presentes Bases, así como a la
          validación de los pases registrados y cumplimiento de participación correspondiente.</strong></p>
          <p><strong>5. REQUISITOS PARA PARTICIPAR:</strong></p>

          <p>Podrá participar el público consumidor de la República Mexicana, que reúna con los siguientes requisitos:</p>
          <p>1. Persona Física con domicilio en la República Mexicana</p>
          <p>2. Que sean mayor de edad</p>
          <p>3. Que cuenten con identificación oficial vigente con fotografía: credencial para votar, cédula profesional o
          pasaporte.</p>
          <p>4. Que efectúe la compra de al menos 1 (un) pase de abordar y 1 (uno) de unidad de carga para viajar a
          través de los buques de Baja Ferries® del 01 de noviembre al 31 de diciembre de 2024.</p>

            <p>5. Haber dado lectura íntegra a los Términos y Condiciones, así como el Aviso de Privacidad y cumplir
            totalmente con los requisitos y condiciones aquí establecidas, la participación implicará la comprensión
            y aceptación a las mismas.</p>

            <p><strong>6. MECÁNICA DE REGISTRO Y PARTICIPACIÓN:</strong></p>
            <p>Los consumidores que cumplan con todos los requisitos que se mencionan en las presentes bases, podrán
            participar por el incentivo ofrecido efectuando los pasos siguientes:</p>
            <ul>
              <li>1. Haber comprado 1 (un) pase de abordar y 1 (uno) de unidad de carga para viajar el mismo día en
              cualquiera de las rutas que tiene actualmente establecidas Baja Ferries® Entre Sinaloa y Baja California
              Sur.</li>
              <li>2. Acceder vía WhatsApp, mediante el número o escaneando el código QR que viene plasmado en los
              materiales promocionales, para acceder de manera directa.</li>
              <li>3. Una vez que haya accedido vía “WhatsApp” de la “Promoción”, tendrá que realizar un pre registro con
              los siguientes datos:
              <ul>
                <li>i. Nombre completo (nombre y apellido paterno y materno)</li>
                <li>ii. Fecha de nacimiento</li>
                <li>iii. Correo electrónico.</li>
                <li>iv. Número de contacto.</li>
              </ul>
              </li>
            </ul>
            <p>Los datos sólo serán registrados la primera vez que participe, en las siguientes participaciones ya contará con un
            pre - registro.</p>
            <p>El proceso de registro terminado implicará la aceptación y permiso tácito del participante para recibir en la dirección
            de correo registrada, notificaciones electrónicas, con información operativa de la promoción, hasta en tanto no se
            exprese el deseo de no recibir dichas notificaciones.</p>
        <p>5. Una vez que el participante efectuó su registro, podrá comenzar su participación, para lo cual se le solicitará
        subir:</p>
        <ul>
          <li>i. Número de pase de abordar</li>
          <li>ii. Número de pase de unidad de carga</li>
          <li>iii. Fecha de compra</li>
          <li>iv. Foto de los pases (los cuales deben estar completos, legibles, sin tachaduras, rayaduras,
        alteraciones o roturas), estos deberán estar registrados dentro del periodo de la vigencia de la
        promoción y coincidir con los datos proporcionados, para concluir deberá dar clic en el botón
        “registrar”.</li>
        </ul>
        <p>En caso de que, dentro del periodo de la vigencia de la Promoción, el participante desee registrar más pases de
        abordar junto a su pase de unidad de carga, deberá efectuar los mismos pasos antes señalados en la mecánica de
        participación, ingresando al “WhatsApp” de la Promoción.</p>
        <p>6. Adicionalmente, cada participante podrá participar en el conteo de la urna por la posibilidad de ser
        acreedor al incentivo final.</p>
        <p>7. El Participante con mayor cantidad de pases de abordar junto a sus pases de unidad de carga
        correctamente registrados, durante los meses de la promoción tendrá mayor oportunidad de ser
        acreedor a uno de los incentivos mensuales. No hay límite en el registro de pases y cada pase de
        abordar junto a su pase de unidad de carga que se registre será una participación.</p>
        <p>8. El participante que realizó su registro correctamente automáticamente participará en la mecánica de
        cuenta cuantos, donde por medio su habilidad y destreza deberá indicar el número de Camioncitos de
        foami que cree están dentro de la urna que se encontrará dentro de los Buques de Baja Ferries®</p>
        <p>9. El participante podrá participar cuantas veces quiera, siempre y cuando registre un pase de abordar
        junto a su pase de unidad carga diferentes en cada uno de sus intentos.</p>
        <p>Los Participantes deberán:</p>
        <p>i. Haber leído y aceptado incondicionalmente las Bases de la Promoción, además del Aviso de Privacidad del
        Organizador de la Promoción, sin lo cual no podrán participar.</p>
        <p>ii. Cumplir con las indicaciones y la mecánica de la Promoción, de conformidad con lo establecido en las
        presentes Bases.</p>
        <p>En caso de que, dentro del periodo de la vigencia de la promoción, el participante desee registrar más pases de
        abordar junto a su pase de unidad de carga, deberá efectuar los mismos pasos antes señalados en la mecánica de
        registro y participación, ingresando al Whatsapp de la promoción. Los participantes no tienen límite en la cantidad de
        pases diferentes durante la temporalidad de la promoción y serán acreedores de los incentivos en los términos que
        más adelante se detallan.</p>
        <p><strong>7. ¿CÓMO PARTICIPAR POR UN INCENTIVO MENSUAL?</strong></p>
        <p>Para que el participante pueda ser acreedor a uno de estos incentivos, deberá contar con la habilidad y destreza
para registrar sus pases en los términos señalados en el númeral 6 y que su registro sea acreedor conforme a la
tabla de prelación de incentivos que se indica.</p>

        <p>Se contabilizarán los registros en dos bloques, el primero de ellos, del 01 al 30 de noviembre de 2024 hasta las
        23:59 horas, cuyos registros de prelación son los siguientes:</p>

        {/* TABLA */}

        <table class="table table-bordered mascota-tabla">
            <tbody>            
                <tr>                                    
                    <th className="">no. Registro Mes 1</th>
                    <th className="">Incentivo</th>                                  
                </tr>
                <tr>
                    <td>685</td>
                    <td>Refrigerador</td>                                                                  
                </tr>
                <tr>
                    <td>1525</td>
                    <td>Lavadora</td>                                                                  
                </tr>
                <tr>
                    <td>1037</td>
                    <td>Televisión</td>                                                           		        					      
                </tr>	
                <tr>
                    <td>2481</td>
                    <td>Monedero</td>                                                                            
                </tr>	                                                                            			                                            
            </tbody>
        </table>
        <p>Una vez concluido el plazo de registro señalado en el párrafo que antecede, se iniciará con el segundo bloque de
        registros, para que el participante pueda ser acreedor a uno de ´los siguientes incentivos, deberá haber realizado su
        registro de manera correcta en fecha 01 al 31 de diciembre de 2024, hasta las 23:59 horas, conforme a la tabla de
        prelación de incentivos que se indica:</p>
        {/* TABLA */}
        
        <table class="table table-bordered mascota-tabla">
            <tbody>            
                <tr>                                    
                    <th className="">no. Registro Mes 2</th>
                    <th className="">Incentivo</th>                                  
                </tr>
                <tr>
                    <td>202</td>
                    <td>Televisión</td>                                                                  
                </tr>
                <tr>
                    <td>268</td>
                    <td>Lavadora</td>                                                                  
                </tr>
                <tr>
                    <td>345</td>
                    <td>Monedero</td>                                                                  
                </tr>
                <tr>
                    <td>409</td>
                    <td>Televisión</td>                                                                  
                </tr>
                <tr>
                    <td>567</td>
                    <td>Lavadora</td>                                                                  
                </tr>
                <tr>
                    <td>763</td>
                    <td>Refrigerador</td>                                                                  
                </tr>
                <tr>
                    <td>1007</td>
                    <td>Monedero</td>                                                                  
                </tr>
                                                                                          			                                            
            </tbody>
        </table>
        <p><strong>8. ¿CÓMO PARTICIPAR POR EL INCENTIVO FINAL?</strong></p>
        <p>Para que el participante pueda participar por el incentivo final, posterior a su registro, deberá dar su cálculo de
        cuántos camioncitos de Foami cree que hay dentro de la urnas las cuales se encontrarán dentro de cada uno de los
        Buques, el participante dará su cálculo mediante su conocimiento y habilidad, de manera que el participante que
        más se acerque al número de camioncitos dentro de la urna sin pasarse será el acreedor del incentivo . En caso de
        existir un empate en el conteo de camioncitos de foami el acreedor se definirá por quien haya dado primero su
        respuesta conforme a los registros del WhatsApp. Las urnas ubicadas en cada uno de los buques contendrán el
        mismo número de camioncitos de fomi, por lo que la participación será única y no por cada urna instalada.</p>

        <p><strong>9. REQUISITOS DE OBTENCIÓN POR INCENTIVO:</strong></p>
        <ul>
          <li>1. Para la entrega del incentivo de prelación descrito en el punto 4, se deberá contar con el pase de
abordar junto a su pase de unidad de carga correspondientes al registro y los mismos deberán de ser
válidos en términos de lo que se indica en los puntos 2 y 3.</li>

          <li>2. Los participantes, solo podrán ser beneficiarios de un incentivo de prelación en una sola ocasión,
          sin embargo, la totalidad de sus participaciones son válidas para el INCENTIVO FINAL.</li>

          <li>3. <u>El pase de abordar junto a su pase de unidad de carga deberá tener fecha del 1 de noviembre al 31
de diciembre de 2024 y corresponder al mismo día ambos pases.</u></li>

          <li>4. El Organizador de la Promoción se reserva el derecho, durante la vigencia de la Promoción y aún después
de haber concluido, de requerir a los Participantes documentación que acredite el cumplimiento exacto de
los requisitos arriba señalados. En caso de que se llegara a comprobar que algún Participante no cumpliera
con todos los puntos, éste será automáticamente descalificado sin ninguna responsabilidad para el
Organizador.</li>

          <li>5. Solo las personas que cumplan con la totalidad de los requisitos anteriormente descritos podrán ser
          consideradas como “Participantes” de la Promoción.</li>
        </ul>


        <p><strong>10. GARANTÍA DE LOS INCENTIVOS:</strong></p>
        <p>La Organizadora de la Promoción no otorga garantía de los beneficios o incentivos que no sean las propias de los
productos que se otorgan al público en general, no obstante, se deja a salvo cualquier garantía que se pueda hacer
valer directamente con el fabricante o proveedor del servicio.</p>

            <p><strong>11. SELECCIÓN Y ASIGNACIÓN DE POSIBLES ACREEDORES:</strong></p>

            <p><strong>a. Incentivos por prelación:</strong></p>

            <p>Al finalizar el mes de participación y dentro de un plazo de 10 días hábiles posteriores, se realizará un corte para
determinar a los posibles acreedores mensuales (ver cuadro de prelación y distribución de incentivos) de 1 (un)
incentivo, quienes serán determinados como aquellos participantes que hayan obtenido por medio de su habilidad
lograr su registro en el número de prelación indicado en las bases.</p>

            <p><strong>b. Premio cuenta cuántos:</strong></p>
            <p>Al finalizar la participación total durante la promoción y dentro de un plazo de 10 días hábiles posteriores, se
determinará al acreedor del automóvil, para ello se realizará un corte para determinar al posible acreedor del
automóvil quien será determinado como aquel participante que haya obtenido el número más cercano sin pasarse o
número exacto de camioncitos de foami dentro de las urnas que se encontrarán en los Buques de Baja Ferries®
durante el periodo total de la promoción de su participación.</p>

            <p><strong>c. MECÁNICA DE DESEMPATE:</strong></p>

            <p>Debido a la naturaleza de la mecánica de la promoción y a que los mismos se definirán por medio de dar la
respuesta correcta sin pasarse, en caso de existir empate en la respuesta del cuenta cuantos, el acreedor se definirá
por quien haya dado su respuesta antes al WhatsApp contemplando día, hora, minutos y segundos, para definir al
ganador.</p>

            <p><strong>12. CONTACTO CON POSIBLES ACREEDORES :</strong></p>
            <p>Los “posibles acreedores” tendrán este carácter hasta en tanto se verifique si cumplieron con todos los requisitos de
participación y serán contactados de 2 a 4 días hábiles siguientes a la conclusión del periodo de participación en el
que fueron determinados como posibles acreedores (gran incentivo ), les será solicitada la confirmación de datos de
registro así como los siguientes documentos que deberán enviar dentro de las 48 (cuarenta y ocho) horas
siguientes de la solicitud al mismo correo del que fue contactado.</p>

            <ul>
              <li>Identificación oficial vigente (IFE/INE, pasaporte, cédula profesional).</li>
              <li>CURP y/ó RFC con homoclave</li>
              <li>Comprobante de domicilio no mayor a tres meses y referencias de domicilio (solo para incentivos
                mensuales)</li>
              <li>Solo en el caso de incentivo de gran premio, se solicitará el envío de los Pases registrados durante la
              promoción y que lo hicieron posible ganador de este.</li>

            </ul>

            <p>Una vez recibidos los documentos antes señalados, éstos se revisarán, verificarán y validarán que cumplen con
todos los requisitos señalados en las presentes bases. En caso de no recibir respuesta por parte del posible
acreedor, o bien, de no recibir la información ni documentación completa dentro de las 48 (cuarenta y ocho) horas
posteriores a haberlos solicitado, se perderá el derecho de reclamar y recibir el incentivo ofrecido, por tanto, no se
les podrá confirmar como ganador y se procederá a contactar con el siguiente participante que resulte “posible
acreedor ” y así sucesivamente, considerando 3 rondas de reasignación de un incentivo.</p>

            <p>En caso de que se contacte a un posible acreedor sustituto, se seguirá el procedimiento de contacto establecido en
el presente numeral, bajo los mismos tiempos y lineamientos establecidos, en caso de que después de 3 rondas de
reasignación, no se pueda entregar el incentivo, la Organizadora se reserva el derecho para decretar que los
incentivos se encuentran vacantes o bien intentar asignarlos en 3 rondas más.</p>



            <p>Para efectos de la asignación de un posible acreedores sustitutos de los incentivos con prelación, en caso de que el
participante con el registro preseleccionado no sea contactable o bien una vez contactado no sea posible validar su
participación en los términos de éste numeral, se procederá con la reasignación del incentivo con el número de
registro anterior, en caso de que ese participante no sea contactable o no sea posible validar su participación se
contactará al registro posterior del preseleccionado y así de manera alternada.</p>

            <p>Para efectos de la asignación de un acreedor sustituto del gran incentivo, en caso de que el participante con el
registro que más se acerque al número de camioncitos en la urna sin pasarse, no sea contactable o bien una vez
contactado no sea posible validar su participación en los términos de éste numeral, se procederá con la reasignación
del incentivo con el siguiente participante con el registro más cercano sin pasarse, hasta en 3 ocasiones y bajo los
lineamientos previamente establecidos.</p>

            <p>En caso de que un posible acreedor original o bien un posible acreedor sustituto que no haya cumplido con la
validación a la que se refiere el presente numeral, pretenda validar de manera posterior su participación, la misma
no será tomada en cuenta y no podrá exigir de la organizadora ni de la Marca cumplimiento alguno de la presente
promoción.</p>

            <p><strong>13. ENTREGA DE INCENTIVOS:</strong></p>
            <p>En el caso de cualquier incentivo, el acreedor deberá firmar en favor del Organizador la hoja que avale la recepción
            del incentivo, mismo que se entregará conforme a lo siguiente:</p>
            <p><strong>a) Entrega de incentivos mensuales.</strong> En las 72 (setenta y dos horas) posteriores a la
confirmación de acreedor será enviado por correo electrónico el No. de guía que avala el envío del
premio al domicilio del acreedor para que pueda hacer el rastreo y recepción de este, de acuerdo con
las indicaciones del proveedor de paquetería. La entrega dependerá completamente del servicio de
mensajería, no obstante, el Organizador dará seguimiento para asegurar la entrega.</p>

            <p><strong>b) Entrega del incentivo identificado como Automóvil.</strong> PPara la entrega del automóvil se realizará
un evento donde se hará entrega del vehículo, en el cual se realizará toma de foto, video y, se hará la
entrega y firma de documentos. Dicho incentivo será entregado en un lapso de 10 a 15 días hábiles
siguientes a la confirmación de ganador. La semana del 27 de enero del 2025 estaremos contactando
al ganador para darle la cita (lugar, fecha y hora) para la entrega del premio.</p>

            <p>Es responsabilidad del acreedor trasladarse por medios propios a la cita en Mazatlán, Sinaloa para
recibir el INCENTIVO FINAL del automóvil, en ningún momento será responsabilidad del Organizador
ni de Baja Ferries®</p>

            <p><strong>14. PUBLICACIÓN DE ACREEDORES :</strong></p>
            <p>Los resultados se darán a conocer una semana posterior a la fecha de terminación de la promoción mensual, en la
            página web www.bajaferries.com.mx/promo-abordo/ganadores.</p>

            <p>La publicación de acreedores del incentivo se llevará a cabo en la página web
            www.bajaferries.com.mx/promo-abordo/ganadores. la semana como fecha máxima el día: <strong>09 de diciembre del
            2024</strong> y el 8 de enero del 2025 respectivamente.</p>

            <p>Los resultados de los acreedores se darán a conocer una vez que se dé por finalizado el proceso de validación y se
            acrediten ganadores y se comunicará a más tardar en la fecha antes señalada, en redes sociales de Baja Ferries®.</p>

            <p><strong>15. MEDIOS DE DIFUSIÓN</strong></p>
            <p>Los medios en lo que se difundirá la promoción son:</p>
            <ul>
              <li>Materiales de comunicación en puntos de venta Baja Ferries® y dentro del Ferri.</li>
              <li>Comunicación en Redes sociales.</li>
            </ul>
            <p><strong>16. LIMITANTES Y CONDICIONES</strong></p>
            <ul>
              <li>La participación del público consumidor en la presente promoción implica la aceptación de la mecánica,
              términos y condiciones, y Aviso de Privacidad establecidas.</li>

              <li>El Participante deberá ser mayor de edad con identificación oficial vigente a la fecha de redención del
incentivo</li>

              <li>Siempre que se registre un pase de abordar junto a su pase de unidad de carga, deberán conservarse como
comprobante pues podrá ser requerido por el Organizador para que proceda la entrega de alguno de los
incentivos ofrecidos.</li>

              <li>El Participante deberá aceptar recibir correos electrónicos del Organizador de la promoción, para ser
              notificado por ese medio en caso de ser acreedor a algún incentivo.</li>

              <li>Los importes de compra o sus remanentes no son transferibles entre Participantes ni canjeables por incentivo
o valor económico alguno y se extinguen al ser utilizados para canje.</li>

              <li>La Organizadora podrá suspender, modificar o cancelar y dejar sin efectos la posibilidad de otorgar el
incentivo ofrecido si sobreviene alguna condición de fuerza mayor que impida la ejecución de la promoción o
del incentivo o por cualquier decisión a su discreción, sin responsabilidad alguna.</li>

              <li>No es válido ni acumulable con otras promociones.</li>
              <li>El Organizador se reserva el derecho de solicitar a los acreedores a los incentivos la autenticidad de la
              información verdadera para validar su participación y en su caso, hacer entrega del incentivo.</li>

              <li>El Organizador no se hace responsable de la disponibilidad de producto participante durante la vigencia de la
Promoción.</li>

              <li>Las decisiones que se adopten respecto a la Promoción serán finales y no se podrán apelar, en ninguna
              circunstancia.</li>

              <li>El Organizador se reserva el derecho de ejercer las acciones que correspondan en la vía judicial, incluyendo
de manera enunciativa más no limitativa, ante las autoridades civiles, penales y/o administrativas contra
cualquier consumidor del que se tenga sospecha que se encuentra interviniendo de cualquier manera el
correcto funcionamiento del WhatsApp.</li>

              <li>El Organizador; sus subsidiarias y/o afiliadas no aceptan ninguna responsabilidad por cualquier falla técnica o
un funcionamiento defectuoso o cualquier otro problema con la red que esté conectado en línea al sistema,
servidores, o proveedores de otro tipo, que puede ser el resultado de cualquier contenido o entrada en la
promoción que no esté correctamente registrado.</li>

              <li>El Organizador no se hace cargo de los gastos que se generen, suministro de energía eléctrica, servicio de
telecomunicaciones, programas y software y hardware, reproductores musicales, ni recomienda o promueve
marca alguna de insumos tecnológicos y cualquier otro requisito de participación.</li>


              <li>De así requerirse, quien resulte acreedor al incentivo, otorga una licencia para el uso de su nombre, imagen
y/o voz para la elaboración de material fotográfico, así como grabaciones de audio y video a utilizarse o
publicarse en los medios de comunicación que el Organizador juzgue convenientes sin remuneración
adicional alguna distinta a la del incentivo recibido de acuerdo con lo establecido en las presentes bases.</li>

              <li>La participación en esta promoción implica la aceptación de la totalidad de sus bases y mecánica de la
              misma, las que son inapelables, el Organizador; es la única habilitada para interpretarlas.</li>
              <li>El incentivo no es intercambiable ni canjeable por dinero en efectivo, ni por cualquier otro incentivo.
· En caso de que la aceptación y/o posesión de los premios generen impuestos el pago de estos serán
responsabilidad exclusiva de los acreedores al incentivo.</li>

              <li>En caso de que los incentivos no sean reclamados conforme a los términos estipulados en las presentes
Bases, el organizador podrá disponer libremente del incentivo o bien reasignarlos a otros participantes a su
elección, siguiendo las bases correspondientes.</li>

              <li>Es responsabilidad del participante proporcionar sus datos de forma correcta y completa ya que esa será la
única forma de contacto en caso de ser posibles ganadores, por lo que si hay errores en sus datos su
participación estará sujeta a la comprobación de los documentos que presente al momento de contacto como
posible ganador, por lo que en caso de no presentar dicha documentación que acredite su identidad será
descalificado automáticamente y no será considerado para la entrega de incentivos y no podrá continuar
participando en la promoción.</li>

              <li>El Organizador de la promoción se reserva el derecho de efectuar la modificación de los incentivos ofrecidos,
              así como de las características de los mismos, por otros de valor similar o aproximado.</li>
              
            </ul>
            <p>Será obligación del participante consultar y aceptar las mismas previo a su participación.</p>
            <p><strong>17. DARÁN MOTIVO A DESCALIFICACIÓN INMEDIATA:</strong></p>
            <ul>
              <li>La participación de cualquier menor de edad y de las personas que no tengan residencia permanente en la
              República Mexicana.</li>

              <li>La participación de Personas Morales, empresas, asociaciones y/o cualquiera otra colectividad.</li>
              <li>La participación de cualquier empleado de la Organizadora y/o cualquiera de las empresas o personas que
              operan la Promoción.</li>

              <li>La participación de cualquier persona que tenga parentesco directo o por afinidad con algún trabajador(a)
de la Organizadora o Patrocinadoras.</li>

              <li>Cualquier motivo de descalificación por quién haya alcanzado alguno de los Incentivos ofrecidos podrá
implicar la anulación de la entrega y las acciones pertinentes para reclamar la reparación del daño o
perjuicio ocasionado en su caso.</li>

              <li>En caso de que un Participante ingrese a registrar sus datos o participar a través del perfil de otro
consumidor, éste será descalificado de la Promoción, entiéndase por descalificado que no se entregará
ningún incentivo y no podrá seguir participando en la promoción.</li>

              <li>Cada participación será considerada a través de una sola cuenta de correo registrada en la base de datos
del Organizador que será aquella con la que hayan registrado las compras de los pases participantes, en
caso de que el Organizador detecte que un Participante interactúa con 2 o más cuentas será descalificado
en ambas y no podrá seguir participando en la Promoción, ni se hará entrega de incentivos de ningún tipo.</li>

              <li>Las compras de los pases deberán ser auténticas conforme a las operaciones en firme realizadas en el
sistema de pagos del WhatsApp conforme a las plataformas participantes. El Organizador se reserva el
derecho de solicitar validación y negar la entrega del incentivo, y anular la participación en operaciones
canceladas o devoluciones realizadas.</li>


              <li>El consumidor participante entiende y acepta que está prohibido violar o intentar violar, de cualquier forma,
la seguridad de la información, de la plataforma del WhatsApp incluyendo de manera enunciativa y no
limitativa, hackeo del sistema o alterar cualquier información, así como el uso de bots o sistemas
automatizados de participación que no correspondan a una participación humana.</li>

              <li>El Organizador y sus filiales se reservan el derecho de limitar o prohibir la participación en esta promoción a
cualquier persona, en el caso de presumir la existencia de fraude o alteración en la Promoción, incluyendo
de manera enunciativa, más no limitativa, la mecánica de la misma, los medios de pago, y cualesquiera
otras que presuman la existencia de fraude o alteración.</li>

              <li>Advertencia: Cualquier intento, hecho por cualquier persona, de dañar, alterar deliberadamente cualquier
sitio de los establecimientos participantes relacionados con esta promoción o alterar la legítima operación
de la misma, constituye una violación a las leyes de carácter civil y penal; razón por la cual, en caso de
presentarse dicho intento, El Organizador; sus subsidiarias y/o afiliadas, se reservan el derecho de
reclamar indemnización por daños y perjuicios frente a cualquier persona, ante las autoridades y por la vía
correspondiente.</li>


              <li>El Organizador se reserva el derecho a descalificar de la promoción a cualquier persona que ponga en
riesgo la integridad y buen desarrollo de la misma, tal es el caso de aquellos denominados “hackers”
(término utilizado para referirse a personas con grandes conocimientos en informática y telecomunicaciones
que son empleados con objetivos personales y/o de lucro, el cual puede ser doloso e ilegal) o los
denominados “caza promociones” que sean o hayan sido detectados como tal (definido como tal a todo
aquel participante que actúa solo o conjuntamente con recursos económicos, materiales o informativos de
forma desleal frente a otros participantes para obtener el beneficio de la promoción sin importar que los
mecanismos que usa sean poco ortodoxos, éticos morales o incluso ilegales), aquellas en que participen
bots, cuentas automatizadas y/o cualesquiera que presupongan un software automatizado con el fin de
adquirir ventaja sobre los otros participantes.</li>

              <li>Los Participantes entienden y aceptan que está prohibido violar o intentar violar, de cualquier forma, la
Promoción, incluyendo de manera enunciativa más no limitativa, proporcionar datos incompletos o falsos, o
intentar interferir con el servicio a cualquier Participante. El Organizador se reserva expresamente el
derecho de descalificar a cualquier participante del cual se tenga duda o sospecha de que está actuando
en contra de las reglas oficiales. Asimismo, se reserva el derecho de prohibir su participación en futuras
promociones.</li>
              <li>Si por alguna razón no fuese posible desarrollar la Promoción en los términos estipulados en los presentes
términos y condiciones, con motivo de fallas técnicas o cualquier otra causa fuera del control del
Organizador, ésta se reserva el derecho de modificar o posponer a su discreción la Promoción, en cuyo
caso, se notificará de dicha circunstancia a los participantes mediante ajuste en los Términos y Condiciones
compartidos en el WhatsApp.</li>


              <li>El acreedor al incentivo deberá seguir todas las direcciones e instrucciones del Organizador o responsables
              de entrega de su incentivo o le será negado.</li>

              <li>El consumidor participante se obliga a proporcionar con veracidad la información solicitada por el
Organizador, en la inteligencia de que en caso de que la información proporcionada por el Participante sea
falsa o incompleta, el Organizador se reserva el derecho de suspender o cancelar su participación.</li>

              <li>No se entregará el incentivo a terceros, ni podrá redimirse por personas distintas a aquellos acreedores a
              Incentivos conforme los lineamientos de la Mecánica.</li>


              <li>En caso de no lograr contactar al acreedor al incentivo o sea rechazado el correo electrónico enviado, se
entenderá por no aceptado el incentivo, eliminando cualquier posibilidad de reclamación posterior y
liberando al Organizador, y/o sus filiales y subsidiarias, de cualquier responsabilidad.</li>

              <li>La responsabilidad del Organizador concluye al momento de la entrega de cada uno de los incentivos a sus
              correspondientes acreedores.</li>

              <li>El Organizador no será responsable por el uso que le brinde el participante a cualquiera de los incentivos,
ni por procesos judiciales, extrajudiciales o de cualquier otra índole que puedan ser interpuestos en relación
con un reclamo de cualquier tipo de responsabilidad, por hechos acontecidos durante o como consecuencia
de la entrega del mismo, así como, relacionados con la calidad, garantías por daños, pérdidas o perjuicios
ocasionados por el uso y disfrute del incentivo.</li>

              <li>Es responsabilidad del participante proporcionar sus datos de forma correcta y completa ya que esa será la
              única forma de contacto en caso de ser posibles ganadores.</li>

              <li>El organizador se reserva el derecho de no entregar el incentivo al posible ganador si no acredita y/o hace
entrega de los documentos mencionados anteriormente.</li>

              <li>El Organizador de la Promoción podrá en cualquier momento suspender la Promoción o la entrega de
incentivos sin responsabilidad alguna, si dicha suspensión es en acato de cualquier orden administrativa o
judicial y/o si se dieren circunstancias de caso fortuito o fuerza mayor o eventos que afecten los intereses
del organizador.</li>

              <li>El Organizador no es responsable de los tiempos o en su caso interrupción del servicio por casos de fuerza
mayor o caso fortuito o cualquier otro aplicable, en su caso el ganador se estará a lo dispuesto por las
políticas del proveedor de dicho servicio.</li>

              <li>El Organizador de la promoción se reserva el derecho de modificar las presentes bases por las
              implicaciones y lineamientos de salud impuestos por las autoridades o por causa de fuerza mayor.</li>
              <li>El participante o consumidor, es responsable de cumplir con los lineamientos y normas de seguridad y
salud impuestas por la autoridad en el Estado o lugar en donde efectúe su participación, por lo que no
podrá atribuir al Organizador, cualquier consecuencia derivada de tal omisión.</li>

        
            </ul>
            <p><strong>18. AUTORIZACIÓN</strong></p>
            <p>El consumidor que participe en esta promoción, autoriza expresa e irrevocablemente a <strong>People Incorporated</strong> S.A.
            de C.V., con domicilio en: Damas No. 129, Colonia San José Insurgentes, Alcaldía Benito Juárez, C.P. 03900, en
Ciudad de México, en un horario de lunes a viernes de las 10:00 a las 19:00 horas y/o cualquier otra empresa que
ésta determine, a difundir en los medios que <strong>People Incorporated</strong>, S.A. de C.V. estime conveniente, su nombre y
apellido completo, país y ciudad de origen, fotografías y uso de imagen o retrato, videos y en general todos aquellos
datos que pudieran requerirse con motivo de la difusión de esta promoción, renunciando expresa e
irrevocablemente, desde la aceptación de las bases, a cualquier tipo de compensación económica, remuneración,
regalía o retribución alguna por dicho uso.</p>

            <p><strong>19. AVISO DE PRIVACIDAD SIMPLIFICADO</strong></p>
            <p>La empresa People Incorporated S.A. de C.V., con domicilio en Damas No. 129, Colonia San José Insurgentes,
Alcaldía: Benito Juárez, C.P. 03900, Ciudad de México, México, con Teléfono: 5552866095, con horario de atención
de lunes a viernes de las 10:00 a las 19:00 horas, será la responsable de recabar y tratar los datos personales de
los participantes.</p>

            <p>Los datos personales que se recabarán i) nombre completo del participante, ii) correo electrónico, iii) datos de la
identificación oficial que exhibe (autoridad emisora y número de identificación), (en los sucesivo “datos personales”),
i los datos recabados tendrán relación directa con su participación en la Promoción y por ende el uso que se dará a
su información será para identificarle, ubicarle, comunicarle y/o contactarle según sea el caso como consecuencia
de su participación en la promoción y/o dinámica y/o campaña publicitaria de la cual el responsable sea organizador.</p>

            <p>De igual forma los datos personales proporcionados podrán ser usados para enviarle mercancía, información sobre
beneficios, campañas publicitarias, promociones, encuestas y estadísticas, finalidades que son accesorias a las
anteriormente mencionadas. La aceptación en la presente promoción representa su aceptación al tratamiento de sus
datos personales en los términos señalados, si usted no está de acuerdo con alguno de los usos anteriormente
enlistados sobre el manejo de sus “datos personales” le solicitamos nos manifieste su negativa al respecto a través
de los medios que se indican en el Aviso de Privacidad Integral.</p>


            <p><strong>20. MODIFICACIONES A LOS TÉRMINOS Y CONDICIONES.</strong></p>
            <p><strong>El Organizador</strong> se reserva el derecho de ampliar o modificar en cualquier momento los presentes términos y
condiciones, por lo que el participante deberá consultar las bases y condiciones constantemente y hasta el término
de la <strong>Promoción</strong>, las ampliaciones o modificaciones a éste documento serán visibles y actualizadas en la página
web correspondiente, por lo que el participante podrá consultar en todo momento la última versión aplicable.</p>

            <p><strong>21. PROPIEDAD INTELECTUAL E INDUSTRIAL.</strong></p>
            <ul>
              <li>Los presentes términos y condiciones de uso no pretenden transferir derechos de propiedad intelectual e
              industrial entre las partes.</li>
              <li>Ninguno de los participantes, pueden utilizar los derechos de propiedad intelectual o industrial, incluidos
nombres, marcas, logotipos, o materiales del Organizador y las entidades, en ningún momento ni, de
cualquier forma.</li>

            </ul>
            <p><strong>22. JURISDICCIÓN APLICABLE</strong></p>
            <p>En caso de queja, podrá acudir a la Procuraduría Federal del Consumidor, Av. José Vasconcelos 208, Colonia
Condesa, Delegación Cuauhtémoc, C.P. 06140, en la Ciudad de México, o a sus correspondientes delegaciones
estatales, o bien, comunicarse al 5625 6700 / 01800 468 8722</p>

            <p>Cualquier controversia suscitada por la promoción y sus complementarias, se regirán por las presentes bases y
como complemento para lo establecido en éstas aplicará la jurisdicción de los Tribunales competentes en la Ciudad
de México, sujetándose todas las partes interesadas a las mismas, renunciando al que por beneficio de su domicilio
presente o futuro pudiese corresponderles.</p>


        </div>
      </div>
    </section>
    </>
  )
}

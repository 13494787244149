import React from 'react'

export const Spotify = () => {
  return (
    <iframe
        title="Spotify"
        src='https://open.spotify.com/embed/playlist/6QUtlxDsaX1eGGgPgfdqXT?utm_source=generator'
        width="100%"
        height="420px"
        frameBorder="0"
        color='red'
        
    />
  )
}
export default Spotify;

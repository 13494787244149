import React, { useState, useEffect } from 'react';
import { format, addDays } from 'date-fns';
import { es } from 'date-fns/locale';
import '../css/viaje_curso.css';
import axios from 'axios';

export default function ViajeCurso() {
  const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

  const [fecha, setFecha] = useState(new Date());
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Actualización de fecha cada segundo
  useEffect(() => {
    const intervalo = setInterval(() => {
      setFecha(new Date());
    }, 1000);
    return () => clearInterval(intervalo);
  }, []);

  // Formatear la fecha actual
  const fechaFormateada = capitalize(
    format(fecha, "EEEE dd 'de' MMMM yyyy", { locale: es })
  );

  // Obtener datos desde el backend
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://g4dcvwgqon5wrdugzyyvchbuei0cpjbr.lambda-url.us-east-1.on.aws/getAllTracking');

        setData(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error al obtener los datos:', error);
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Asignar estilos según estatus
  const getStatusStyle = (estatus) => {
    switch (estatus.toLowerCase()) {
      case 'programado':
        return { color: '#007BFF', fontWeight: '600' };
      case 'en curso':
        return { color: '#28A744', fontWeight: '600' };
      default:
        return {};
    }
  };

  // Días de la semana en español
  const diasSemana = ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'];

  // Ordenar los días a partir del día actual
  const ordenarDias = () => {
    const diaActual = fecha.getDay(); // Índice del día actual (0 = Domingo, 6 = Sábado)
    return [...diasSemana.slice(diaActual), ...diasSemana.slice(0, diaActual)];
  };

  // Agrupar datos por día
  const agruparPorDia = () => {
    const datosAgrupados = diasSemana.reduce((acc, dia) => {
      acc[dia] = [];
      return acc;
    }, {});

    data.forEach((row) => {
      const dia = format(new Date(row.fecha_salida), 'eeee', { locale: es }).toLowerCase();
      datosAgrupados[dia].push(row);
    });

    return datosAgrupados;
  };

  const diasOrdenados = ordenarDias();
  const datosAgrupados = agruparPorDia();

  if (loading) return <p>Cargando datos...</p>;
  if (error) return <p>Error al cargar datos: {error.message}</p>;

  return (
    <>
      <section>
        <div className="header_section">
          <div className="container header_section_box">
            <div className="header_section_title">
              <h3>Itinerario</h3>
              <p>Conoce la hora estimada de llegada para tu viaje</p>
            </div>
          </div>
        </div>
      </section>

      <section className="panel_viaje_curso">
        <div className="container">
          <div className="content-viaje-curso">
            <div className="barra1_viaje_curso">
              <label>{fechaFormateada}</label>
              {/*<label> Travesía en tiempo real</label>*/}
              <label>Próximas travesías</label>
            </div>
            <div>
              {diasOrdenados.map((dia) => (
                <div key={dia} style={{ marginBottom: '2rem' }}>
                  <h4 style={{ textTransform: 'capitalize' }}>{dia}</h4>
                  <table
                    border="1"
                    style={{ width: '100%', textAlign: 'left' }}
                    className="viaje-curso-tabla" >
                    <thead>
                      <tr>
                        <th>Ruta</th>
                        <th>Buque</th>
                        <th>Salida</th>
                        <th>Llegada</th>
                        <th>Estatus</th>
                        <th>Muelle</th>
                      </tr>
                    </thead>
                    <tbody>
                      {datosAgrupados[dia].length > 0 ? (
                        datosAgrupados[dia].map((row) => (
                          <tr key={row.id}>
                            <td>{row.ruta}</td>
                            <td>{row.buque}</td>
                            <td>{row.salida_hora} HRS.</td>
                            <td>{row.llegada_hora} HRS.</td>
                            <td style={getStatusStyle(row.estatus)}>{row.estatus}</td>
                            <td>{row.muelle}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="5" style={{ textAlign: 'center' }}>
                            No hay datos disponibles para este día.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}


import React from 'react'
import '../css/promo.css'
import { Outlet, Link } from "react-router-dom";
import reservacion from '../assets/images/cta/reservacion.svg'
import rutas from '../assets/images/cta/rutasyhorarios.svg'
import preguntas from '../assets/images/cta/tarifas.svg'
import Booking from '../components/form/Booking';
import Footer from '../components/Footer'

//Imagenes
import puroBajaSur from '../assets/images/promociones/promocion_ninos-cuadrado.png'
import promoMascota from '../assets/images/promociones/inapam.png'
import promoAyudante from '../assets/images/promociones/promo-operador.png'
import promoAbordo from '../assets/images/promociones/promo-abordo.png'

import viajeEnGrupo from '../assets/images/viajeGrupo/grupoImg.webp'

export default function Promociones() {
  return (
    <>
    <section>
        <div className='header_section'>
            <div className='container header_section_box'>
                <div className='header_section_title'>
                    <h3>Promociones</h3>
                    <p>Aprovecha nuestras ofertas y ¡planea tu próximo viaje hoy mismo!</p>
                </div>                
            </div>
        </div>
    </section>
    <section className='promociones_zone'>
        <Booking />
        <div className='container'>
            <div className='promociones_zone_container'>
                {/*<div className='promociones_zone_box'>
                    <div>
                    <Link to="/transportando-carga">
                        <img src={transportandoCarga} alt='transporta carga con baja ferries' />
                        </Link>  
                    </div>
                    
                </div>
                  
                <div className='promociones_zone_box'>    
                    <div>
                    <Link to='/promo-buen-fin'>
                        <img src={promoBuenFin} alt='transporta carga con baja ferries' />  
                    </Link>    
                    </div>                
                   
                </div> */}
                <div className='promociones_zone_box'>
                    <div>
                    <Link to='/promo-ninos'>
                        <img src={puroBajaSur} alt='transporta carga con baja ferries' />
                        </Link> 
                    </div>                    
                </div>
                <div className='promociones_zone_box'>    
                    <div>
                    <Link to='/promo-adulto-mayor'>
                        <img src={promoMascota} alt='transporta carga con baja ferries' />  
                    </Link>    
                    </div>                
                   
                </div> 
                <div className='promociones_zone_box'>    
                    <div>
                    <Link to='/promo-ayudante-operador'>
                        <img src={promoAyudante} alt='transporta carga con baja ferries' />  
                    </Link>    
                    </div>                
                   
                </div> 
                <div className='promociones_zone_box'>    
                    <div>
                    <Link to='/promo-abordo'>
                        <img src={promoAbordo} alt='transporta carga con baja ferries' />  
                    </Link>    
                    </div>                
                   
                </div> 
                
                      
                         
                      
                                       
            </div>
        </div>        

    </section>

    <section className='viajeEnGrupo-section'>
        <div className='container'>
            <div className='viajaEnGrupo-cta'>
                <div className='viajaEnGrupo-cta-img'>
                    <img src={viajeEnGrupo} alt='Viaja en grupo'/>
                </div>
                <div className='viajaEnGrupo-cta-text'>
                    <h2>¿Estas organizando un viaje en grupo? </h2>
                    <p>Ofrecemos tarifas especiales y servicios personalizados para grupos de 20 personas o más. Comparte con nosotros la siguiente información, nos contactaremos contigo.</p>
                    <div><Link to="/viaje-en-grupo">Ver más</Link></div>
                </div>

            </div>

        </div>
    </section>
    
    <section className="callToAction">
        <div className='container'>
            <div>
                <h3> </h3>
            </div>
            <div className='callToAction_row'>
                <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={preguntas} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Consulta nuestras tarifas</h4>
                            </div>
                            <div>
                                <p>Consulta nuestras tarifas y reserva con anticipación para obtener el mejor precio para viajar.</p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                            <Link to="/tarifas">Más información</Link>     
                        </div>
                    </div>
                </div>
                <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={rutas} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Rutas y Horarios</h4>
                            </div>
                            <div>
                                <p>Explora nuestras rutas y encuentra el horario que mejor se ajuste a tus planes de viaje.</p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                            <Link to="/rutas_horario">Ir ahora</Link>     
                        </div>
                    </div>
                </div>
                {/*<div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={whatsapp} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Envianos un WhatsApp</h4>
                            </div>
                            <div>
                                <p>Comunícate con nosotros al instante. Ya sea que tengas preguntas sobre nuestro servicio, o sobre tu reservación nuestro equipo está listo para ayudarte.</p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                            <a href="https://wa.me/5218003377437?text=Me%20interesa%20una%20cotizaci%C3%B3n" target="_blank" rel="noopener noreferrer">Enviar WhatsApp </a>                    
                        </div>
                    </div>
                </div>*/}
                 <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={reservacion} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>¿Listo para reservar?</h4>
                            </div>
                            <div>
                                <p>Inicia tu aventura hoy. Compra tus boletos y emprende un inolvidable viaje por el Mar de Cortés. </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                        <a href="tel:8003377437" target="_blank" rel="noopener noreferrer">Llamar ahora </a>                        
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <Outlet />
    <Footer />  
    
    </>
  )
}

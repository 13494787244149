import React, { useState } from 'react';
import '../css/oceantrack.css';

export default function OceanTrack() {
  const [ruta, setRuta] = useState('');
  const [buque, setBuque] = useState('');
  const [salida, setSalida] = useState('');
  const [estatus, setEstatus] = useState('');
  const [muelle, setMuelle] = useState('');
  const [fecha_alta, setFechaAlta] = useState('');
  const [tiempo_vida, setTiempoVida] = useState('');
  const [visible, setVisible] = useState('');

  const handleRutaChange = (e) => {
    e.preventDefault();
    const fechaActual = new Date();
    const fecha = fechaActual.toLocaleDateString(); // Ejemplo: 02/12/2024
    const hora = fechaActual.toLocaleTimeString(); // Ejemplo: 14:30:00

    setFechaAlta (`${fecha} ${hora}`);
    setTiempoVida (`${fecha} ${hora}`);
    setVisible(true);
   

    const selectedRuta = e.target.value;
    setRuta(selectedRuta);

    // Configuración automática según la ruta seleccionada
    if (selectedRuta === 'La Paz - Topolobampo' || selectedRuta === 'Topolobampo - La Paz') {
      setBuque('California Star');
      setMuelle('Muelle 1');
    } else if (selectedRuta === 'La Paz - Mazatlán' || selectedRuta === 'Mazatlán - La Paz') {
      setBuque('Mexico Star');
      setMuelle('Muelle 2');
    } else {
      setBuque('');
      setMuelle('');
    }
  };

  

  const handleSubmit = async (e) => {
    e.preventDefault();                
  

 
    console.log('Registro: ', ruta, buque, salida,  estatus,  muelle, fecha_alta,  tiempo_vida, visible,); 
    
    try {
     
        await fetch('https://g4dcvwgqon5wrdugzyyvchbuei0cpjbr.lambda-url.us-east-1.on.aws/tracking',{
        
            method:'POST',
            body: JSON.stringify({  ruta,
              buque,
              salida,
              estatus,
              muelle,          
              fecha_alta,
              tiempo_vida,
              visible}),
            headers: {'Content-Type': 'application/json'},
        });

      // Limpiar campos después de enviar el correo         
      setRuta('');
      setBuque('');
      setSalida('');
      setEstatus('');
      setMuelle('');
      console.log('Formulario enviado con éxito');
        
    } catch (error) {
        console.error('Error al enviar el correo:', error);
    }
}


  return (
    <>
      <section className="formulario">
        <div className="container">
          <div className="formulario-content">
            <div className="formulario-box">
              <div className="form-box">
                <label>Ruta:</label>
                <select value={ruta} onChange={handleRutaChange}>
                  <option value="">Seleccione una ruta</option>
                  <option value="La Paz - Topolobampo">La Paz - Topolobampo</option>
                  <option value="Topolobampo - La Paz">Topolobampo - La Paz</option>
                  <option value="La Paz - Mazatlán">La Paz - Mazatlán</option>
                  <option value="Mazatlán - La Paz">Mazatlán - La Paz</option>
                </select>
              </div>
              <div className="form-box">
                <label>Buque:</label>
                <select value={buque} onChange={(e) => setBuque(e.target.value)}>
                  <option value="">Seleccione un buque</option>
                  <option value="California Star">California Star</option>
                  <option value="Mexico Star">Mexico Star</option>
                </select>
              </div>
              <div className="form-box">
                <label>Salida:</label>
                <input
                  type="datetime-local"
                  value={salida}
                  onChange={(e) => setSalida(e.target.value)} />
              </div>
              <div className="form-box">
                <label>Estatus:</label>
                <select value={estatus} onChange={(e) => setEstatus(e.target.value)}>
                  <option value="">Seleccione un estatus</option>
                  <option value="PROGRAMADO">Programado</option>
                  <option value="EMBARCANDO">Embarcando</option>
                  <option value="EN CURSO">En curso</option>
                  <option value="FINALIZADO">Finalizado</option>
                </select>
              </div>
              <div className="form-box">
                <label>Muelle:</label>
                <select value={muelle} onChange={(e) => setMuelle(e.target.value)}>
                  <option value="">Seleccione un muelle</option>
                  <option value="Muelle 1">Muelle 1</option>
                  <option value="Muelle 2">Muelle 2</option>
                </select>
              </div>
              <div className="form-box">
                <button onClick={handleSubmit}>Guardar</button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import '../css/viaje_curso.css';
import axios from 'axios';

export default function OceanTraking() {
  const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

  const [fecha, setFecha] = useState(new Date());
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const intervalo = setInterval(() => {
      setFecha(new Date());
    }, 1000);
    return () => clearInterval(intervalo);
  }, []);

  const fechaFormateada = capitalize(format(fecha, "EEEE dd 'de' MMMM yyyy", { locale: es }));

  useEffect(() => {
    const fetchData = async () => {
      try {
        
        const response = await axios.get('https://g4dcvwgqon5wrdugzyyvchbuei0cpjbr.lambda-url.us-east-1.on.aws/getTrackingEmbarque');

        setData(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error al obtener los datos:', error);
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const getOrderedDays = () => {
    const daysOfWeek = ['domingo', 'lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado'];
    const todayIndex = fecha.getDay();
    return daysOfWeek
      .slice(todayIndex)
      .concat(daysOfWeek.slice(0, todayIndex))
      .map((day) => capitalize(day));
  };

  const groupedData = getOrderedDays().reduce((acc, day) => {
    acc[day] = [];
    return acc;
  }, {});

  // Agrupar datos usando el campo 'dia'
  data.forEach((row) => {
    const diaFormatted = capitalize(row.dia);
    if (groupedData[diaFormatted]) {
      groupedData[diaFormatted].push(row);
    }
  });

  if (loading) return <p>Cargando datos...</p>;
  if (error) return <p>Error al cargar datos: {error.message}</p>;

  return (
    <>
      <section>
        <div className="header_section">
          <div className="container header_section_box">
            <div className="header_section_title">
              <h3>Itinerario</h3>
              <p>Conoce la hora estimada de llegada para tu viaje</p>
            </div>
          </div>
        </div>
      </section>

      <section className="panel_viaje_curso">
        <div className="container">
          <div className="content-viaje-curso">
            <div className="barra1_viaje_curso">
              <label>{fechaFormateada}</label>
               {/*<label> Travesía en tiempo real</label>*/}
               <label>Próximos viajes</label>
            </div>
            {getOrderedDays().map((day) => (
              <div key={day} className="daily-table">
                <h4>{day}</h4>
                <table border="1" style={{ width: '100%', textAlign: 'left' }} className="viaje-curso-tabla">
                  <thead>
                    <tr>
                      <th>Ruta</th>
                      <th>Buque</th>
                      <th>Salida</th>
                      <th>Llegada</th>
                      <th>Muelle</th>
                      <th>Estatus</th>                      
                    </tr>
                  </thead>
                  <tbody>
                    {groupedData[day].length > 0 ? (
                      groupedData[day].map((row) => (
                        <tr key={row.id}>
                          <td>{row.ruta || ''}</td>
                          <td>{row.buque || ''}</td>
                          <td>{row.salida_hora || ''} HRS.</td>
                          <td>{row.llegada_hora || ''} HRS.</td>
                          <td>{row.muelle || ''}</td>
                          <td style={{ fontWeight: '600', color: row.estatus === 'en curso' ? '#28A744' : '#007BFF' }}>
                            {row.estatus || ''}
                          </td>
                        
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="5" style={{ textAlign: 'center' }}>
                          No hay datos disponibles
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

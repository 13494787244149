import React from 'react'
import { Outlet, Link } from "react-router-dom";
import '../../css/destino.css'
import ImageSlider from '../sliders/SliderDestinos';
import promociones from '../../assets/images/cta/promociones.svg'
import formulario from '../../assets/images/formulario-de-contacto.svg'
import mapa from '../../assets/images/pregutas.png'
import Footer from '../Footer'
import {img1Lapaz, img2Lapaz, img3Lapaz} from '../../assets/base64'

export default function Lapaz() {
    const images = [

        {
            image: img1Lapaz,
            title: 'Catedral de Nuestra Señora de La Paz',
            text: 'La capital del estado de Baja California Sur también conocida como la puerta al Mar de Cortés, es el lugar perfecto para disfrutar de unas relajadas y divertidas vacaciones. ',
            subtext: 'La Paz, Baja California Sur',
        },
        {
            image: img2Lapaz,
            title: 'Playa Balandra',
            text: 'Si te gusta la aventura y estar en el mar, puedes visitar una de las más famosas playas que ofrece la ciudad. Balandra, reconocida por sus cristalinas aguas y la formación rocosa en forma de hongo, se ha convertido en uno de los símbolos más representativos de la ciudad.',
            subtext: 'La Paz, Baja California Sur',
        },
        {
            image: img3Lapaz,
            title: 'Isla Espíritu Santo',
            text: 'Este increíble lugar, que fue nombrado por la UNESCO como Patrimonio de la Humanidad, alberga cerca del 40% de las especies de mamíferos marinos que existen en el mundo. ',
            subtext: 'La Paz, Baja California Sur ',
        },
        // Agrega más imágenes según sea necesario
      ];
    
  return (
    <>
        <section>
            <div className='header_section'>
                <div className='container header_section_box'>
                    <div className='header_section_title'>
                        <h3>La Paz, Baja California Sur.</h3>
                        <p>Conoce La Paz B.C.S como destino</p>
                    </div>                
                </div>
            </div>
        </section>

        <section>
            <ImageSlider images={images}/>
        </section>

        <section className='destino'>            
            <div className='container'>
                <div className='destino_info_util'>
            
                    <div className='content_destinos_info'>
                        <div className='box_destinos_info'>
                            <h3><i class="fi fi-rr-world"></i> Geografía </h3>
                            <ul>
                                <li><strong>Ubicación: </strong> Situada en la península de Baja California, a orillas del Mar de Cortés.</li>
                                <li><strong>Fundación: </strong> La Paz fue fundada en 1535 por el conquistador español Hernán Cortés.</li>                                
                            </ul>
                        </div>
                        <div className='box_destinos_info'>
                            <h3><i class="fi fi-rr-cloud-sun"></i> Clima</h3>
                            <ul>
                                <li><strong>Tipo de Clima: </strong> Árido y semiárido</li>
                                <li><strong>Temperaturas: </strong> Oscilan entre 15°C en invierno y 40°C en verano.</li>
                                <li><strong>Temporada de Lluvias: </strong> Principalmente de agosto a septiembre.</li>
                            </ul>
                        </div>
                        <div className='box_destinos_info'>
                            <h3><i class="fi fi-rr-population"></i> Población</h3>
                            <ul>
                                <li><strong>Población estimada: </strong>Alrededor de 798,000 habitantes.</li>
                                <li><strong>Principales Ciudades: </strong>La Paz, Los Cabos (incluyendo Cabo San Lucas y San José del Cabo), Ciudad Constitución y Loreto.</li>                        
                            </ul>
                        </div>
                        <div className='box_destinos_info'>
                            <h3><i class="fi fi-rr-deposit-alt"></i> Economía</h3>
                            <label>Sectores Principales:</label>
                            <ul>
                                <li><strong>Turismo: </strong>Uno de los motores principales de la economía, especialmente en Los Cabos, que atrae a turistas de todo el mundo por sus playas, resorts y vida nocturna.</li>
                                <li><strong>Pesca y Acuacultura: </strong>Producción de mariscos y pesca deportiva. Importante actividad económica debido a la ubicación costera del estado.</li>
                                <li><strong>Agricultura: </strong>Producción de frutas y hortalizas, especialmente en la región de Valle de Santo Domingo.</li>
                                <li><strong>Minería: </strong>Extracción de minerales como el oro y el yeso.</li>
                            </ul>
                        </div>    
                        <div className='box_destinos_info'>
                            <h3><i class="fi fi-rr-bus"></i> Transporte</h3>
                            <label>Aeropuertos:</label>
                            <ul>                            
                                <li><strong>Aeropuerto Internacional de La Paz (LAP): </strong>Sirve a la capital del estado.</li>                                                   
                            </ul>
                            <label>Transporte terrestre:</label>
                            <ul>
                                <li><strong>Transporte Urbano: </strong>Buses, taxis y servicios de transporte privado como Uber, Didi e inDriver son comunes para moverse dentro de la ciudad.</li>
                            </ul>
                            <label>Transporte marítimo:</label>
                            <ul>
                                <li><strong>Puerto de Pichilingue: </strong>Principal puerto de ferry que conecta La Paz con el continente, incluyendo rutas a Mazatlán y Topolobampo.</li>                                                
                            </ul>
                        </div>                

                    </div>
                    <div className='content_destinos_info'>
                        
                        <div className='box_destinos_info'>
                            <h3> <i class="fi fi-rr-tour-guide-people"></i> Turismo</h3>
                            <label>Principales Atractivos:</label>
                            <ul>                             
                                <li><strong>Malecón de La Paz: </strong>Extensa y pintoresca zona de paseo con esculturas, restaurantes y vistas al mar.</li>
                                <li><strong>Playa Balandra: </strong>Famosa por sus aguas cristalinas y formaciones rocosas únicas, ideal para nadar y practicar kayak.</li>
                                <li><strong>Isla Espíritu Santo: </strong>Área protegida famosa por el snorkeling, buceo, y observación de fauna marina.</li>
                                <li><strong>Museo de La Ballena y Ciencias del Mar: </strong> Dedicado a la historia natural y conservación de la vida marina.</li>
                                <li><strong>Catedral de Nuestra Señora de La Paz: </strong>Hermosa iglesia histórica en el centro de la ciudad.</li>
                            </ul>                            
                        </div>
                       
                        <div className='box_destinos_info'>
                            <h3><i class="fi fi-rr-mountains"></i> Naturaleza y Ecoturismo</h3>
                            <label>Parques Nacionales y Reservas:</label>
                            <ul>
                                <li><strong>Parque Nacional Bahía de Loreto: </strong>Ideal para el avistamiento de ballenas y delfines.</li>
                                <li><strong>Reserva de la Biosfera El Vizcaíno: </strong>Una de las áreas naturales protegidas más grandes de México, hogar de ballenas grises.</li>
                                <li><strong>Parque Nacional Cabo Pulmo: </strong>Patrimonio de la Humanidad por la UNESCO, conocido por su arrecife de coral, ideal para snorkeling, buceo, y kayak.</li>                       
                                <li><strong>Isla Espíritu Santo: </strong>Conocido por su arrecife de coral.</li>        
                                <li><strong>Reserva de la Biosfera Sierra de la Laguna: </strong>Área protegida con senderos para caminatas y observación de flora y fauna.</li>       
                            </ul>
                            <h3><i class="fi fi-rr-kayak"></i> Actividades</h3>
                          
                            <ul>
                                <li> Pesca deportiva, avistamiento de ballenas, kayak, paddleboard, y ciclismo.</li>                   
                            </ul>
                        </div>

                    </div>
                </div>
            </div>
        </section>

        <section className="callToAction">
        <div className='container'>
            <div>
                <h3> </h3>
            </div>
            <div className='callToAction_row'>
                <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={formulario} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Consulta nuestras tarifas</h4>
                            </div>
                            <div>
                                <p>Consulta nuestras tarifas y reserva con anticipación para obtener el mejor precio para viajar.  </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                            <Link to="/tarifas">Ver tarifas</Link>     
                        </div>
                    </div>
                </div>
                <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={mapa} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Preguntas frecuentes</h4>
                            </div>
                            <div>
                                <p>Aquí encontrarás respuestas sobre nuestros servicios, políticas y más información para un viaje sin contratiempos. </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                            <Link to="/preguntas-frecuentes">Más información</Link>     
                        </div>
                    </div>
                </div>
                {/*<div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={whatsapp} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Envianos un WhatsApp</h4>
                            </div>
                            <div>
                                <p>Comunícate con nosotros al instante. Ya sea que tengas preguntas sobre nuestro servicio, o sobre tu reservación nuestro equipo está listo para ayudarte.</p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                        <a href="https://wa.me/5218003377437?text=Me%20interesa%20una%20cotizaci%C3%B3n" target="_blank" rel="noopener noreferrer">Enviar WhatsApp </a>                    
                        </div>
                    </div>
                </div>*/}
                <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={promociones} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Promociones y Descuentos</h4>
                            </div>
                            <div>
                                <p>Descubre las mejores promociones y tarifas especiales para planificar tu viaje al mejor precio.</p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                        <Link to="/promociones">Ir ahora</Link>                      
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <Outlet />

        <Footer />
    
    
    </>
  )
}
